import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {UpdaterService} from './updater.service';
import {Story} from '../model/story.model';
import {AngularFireStorage} from '@angular/fire/storage';
import {Observable} from 'rxjs';


export enum DownloadState {
  NONE,
  DOWNLOADING,
  DOWNLOADED,
  ERROR
}

@Component({
  selector: 'app-updater',
  templateUrl: './updater.component.html',
  styleUrls: ['./updater.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UpdaterComponent implements OnInit {


  stories: Story[] = [];
  isLoadingStories = true;
  selectedStoryId = 0;
  archiveUrl: Observable<string | null>;
  downloadStatus: DownloadState = DownloadState.NONE;
  DownloadStatus = DownloadState;

  constructor(private authService: UpdaterService, private storage: AngularFireStorage) { }

  ngOnInit(): void {
    this.authService.getStories((array: Story[]): void => {
      this.stories = array;
      this.isLoadingStories = false;
      this.onArrayLoaded();
    });
  }

  download(): void {
    const story = this.getStory(this.selectedStoryId);
    if (story === undefined) {
      return;
    }
    const ref = this.storage.ref('stories/' + story.id + '/archives/' + Story.getFormattedVersion(story.version) + '.zip');
    this.archiveUrl = ref.getDownloadURL();
    this.archiveUrl.subscribe(data => {
      if (data === null || data === undefined) {
        alert('Error : couldn\'t find the archive url');
        return;
      }
      this.downloadStatus = DownloadState.DOWNLOADING;
      this.authService.update(story, data, (status: boolean): void => {
        if (status) {
          this.downloadStatus = DownloadState.DOWNLOADED;
        } else {
          this.downloadStatus = DownloadState.ERROR;
        }
      });
    });
  }

  onSelectStory(event: any): void {
    this.downloadStatus = DownloadState.NONE;
    this.selectedStoryId = +event.target.value;
  }

  private getStory(id: number): Story|undefined {
    return this.stories.find(x => x.id === id);
  }
  onArrayLoaded(): void {

  }
}
