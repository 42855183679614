<div class="card">
  <h1>Sutoko archives updater</h1>
  <p>Mise à jour des archives en ligne</p>


  <div class="row">
    <div class="spinner-grow" role="status" *ngIf="this.isLoadingStories">
      <span class="visually-hidden">Loading...</span>
    </div>
    <select #storiesSelector class="form-select" aria-label="Default select example" title="stories" name="stories" id="stories" (change)="onSelectStory($event)">
      <option selected value="0">Selectionnez une histoire</option>
      <option *ngFor="let story of stories" value="{{story.id}}">{{story.id}} - {{story.title}} - ({{story.version}})</option>
    </select>
  </div>

  <p *ngIf="downloadStatus === DownloadStatus.DOWNLOADING">Status : {{ storiesSelector.value }} téléchargement...</p>
  <p *ngIf="downloadStatus === DownloadStatus.DOWNLOADED"> <lottie-player src="https://assets9.lottiefiles.com/packages/lf20_Dcbowq.json"  background="transparent"  speed="1"  style="width: 48px; height: 48px; margin: auto;"  loop  autoplay></lottie-player> Status : {{ storiesSelector.value }} téléchargé!</p>
  <p *ngIf="downloadStatus === DownloadStatus.ERROR">Status : {{ storiesSelector.value }} erreur</p>
  <button class="blue-button" [disabled]="selectedStoryId == 0" (click)="download()">
    <img src="assets/images/ic_refresh.svg" alt="" height="20px">
     Update
  </button>
</div>
