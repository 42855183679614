export class Story {
  constructor(
    public id: number,
    public title: string,
    public version: string
  ){}

  static getFormattedVersion(version: string): string {
    return version.split('.').join('_');
  }
}

