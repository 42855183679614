import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {DownloadState, UpdaterComponent} from './updater/updater.component';
import {RouterModule, Routes} from '@angular/router';
import {UpdaterService} from './updater/updater.service';
import {HttpClientModule} from '@angular/common/http';
import {AngularFireModule} from '@angular/fire';
import {environment} from '../environments/environment';
import {AngularFireStorageModule, BUCKET} from '@angular/fire/storage';
import {CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';

const routes: Routes = [
  {path: '', component: UpdaterComponent}
];

@NgModule({
  declarations: [
    AppComponent,
    UpdaterComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireStorageModule,
    RouterModule.forRoot(routes),
  ],
  providers: [UpdaterService, { provide: BUCKET, useValue: 'sutori-cbac9.appspot.com' }],
  bootstrap: [AppComponent],
  schemas : [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
