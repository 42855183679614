import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {JsonArray, JsonObject} from '@angular/compiler-cli/ngcc/src/packages/entry_point';
import {Story} from '../model/story.model';
import { isDevMode } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UpdaterService {

  constructor(private http: HttpClient) { }

  getStories(onComplete: (array: any) => void): void {
    const url = isDevMode() ? 'https://localhost:8000/api/stories' : 'api/stories';
    this.http.post<Story[]>(url, {}).subscribe(data => {
      onComplete(data);
    });
  }

  update(story: Story, archiveUrl: string, onComplete: (str: boolean) => void): void {
    const url = isDevMode() ? 'https://localhost:8000/api/story/archive-download' : 'api/story/archive-download';
    this.http.post<any>(url, {sid : story.id, version : story.version, url : archiveUrl}).subscribe(data => {
      onComplete(data.file_saved);
    });
  }
}
